import $ from 'jquery';
import './animations.scss';
let play = function(){
	let wh = $(window).height();
	$('.has-animation').each(function(){
		let rect = this.getBoundingClientRect();
		if (rect.y < wh / 2){
			$(this).removeClass('has-animation');		
		}
	})
}
$(document).ready(play);
$(window).scroll(play);
$(document).on('webfactory-block-changed', '*', play);
