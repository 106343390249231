import $ from 'jquery';
import './index.scss';
import './animation.js';
require ('slick-carousel');
$.fn.webfactoryCarousel = function(){
  let data = $(this).data();
  $(this).show();
  
  if (!$(this).children().length){
    return;
  }
	let classes = ($(this).children().attr('class') || '').split(' ').filter(x=>!x.includes('col-md-')).join(' ');
	$(this).children().attr('class', classes);
	data.prevArrow = '<span class="text-primary slick-control slick-control-prev wf-icon-arrow-left wf-icon-big d-flex align-items-center justify-content-center mb-5" ></span>'
	data.nextArrow = '<span class="text-primary slick-control slick-control-next wf-icon-arrow-right wf-icon-big d-flex align-items-center justify-content-center mb-5" ></span>'
  $(this).slick({   
    layout: data.layout,
    infinite: data.infinite,
    slidesToShow: data.slidesToShow,
    slidesToScroll: data.slidesToScroll,
    prevArrow: data.prevArrow,
	  nextArrow: data.nextArrow,
    dots: data.dots,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          arrows: false,
          swipe: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          swipe: true
        }
      }
    ]
  });
}
let init = function($scope){
  if (!$scope){
    $scope = $('body');
	}

	$scope.find('[data-layout="slick-carousel"]:not(.slick-initialized)').each(function(){
		$(this).webfactoryCarousel();
	});
} 
$(function(){
	init();
	$(document).on('webfactory.block.update', function(e){
		[0,500,1000,1500,2000,2500,5000].map(t=>{
			setTimeout(()=>init($(this)), t);
		})
	})
})
